import {type Location, type NavigationType} from '@remix-run/react';

import type {
  Image as CoreTypesImage,
  ProductSortKeys,
  ProductStatus,
  ProductWebSortKeys,
  Scalars,
} from '~/types/graphql/core-types';

/** Pairs of { formFieldName: errorMessage } */
export type FormErrorState<TKey extends string> = {
  [key in TKey]?: string;
};

export enum DetailsPageMode {
  Create = 'create',
  Update = 'update',
}

export type MetafieldType =
  | 'single_line_text_field'
  | 'list.single_line_text_field'
  | 'list.product_reference'
  | 'boolean';

// partially taken from @shopify/app-bridge/actions/ResourcePicker/index.d.ts - This is a subset of only the properties we need.
export interface ResourcePickerProduct {
  id: string;
  title: string;
  images: {altText?: string; originalSrc: string}[];
  variants: {price: string}[];
  handle: string;
  status: ProductStatus;
}

interface PriceV2 {
  amount: Scalar<'Decimal'> | null;
}

export type Scalar<TKey extends keyof Scalars> = Scalars[TKey]['output'];

export interface PriceRangeV2 {
  minVariantPrice: PriceV2;
  maxVariantPrice: PriceV2;
}

// shared AppData between our normal app and our module app
export interface AppData {
  appEnv: string;
  apiKey: string;
  host: string;
  env: string;
  revision: string;
}

export type Resource =
  | 'AppInstallation'
  | 'Product'
  | 'Collection'
  | 'Metafield'
  | 'MetafieldDefinition'
  | 'Metaobject'
  | 'MetaobjectDefinition'
  | 'OnlineStoreFilterSetting'
  | 'OnlineStoreFilterSettingGroup'
  | 'OnlineStoreFilterSettingDynamicGroup'
  | 'OnlineStoreFilterSettingOption'
  | 'TaxonomyValue';
export type Gid<TResource extends Resource> =
  | `gid://shopify/${TResource}/${number}`
  // eslint-disable-next-line @typescript-eslint/ban-types
  | (string & {});

// This gets the Gid type for any object that has a
// __typename property with one of the values in the Resource type
export type GidByTypename<T extends {__typename: string}> =
  T['__typename'] extends Resource ? Gid<T['__typename']> : never;

export enum MetaobjectSortKey {
  UpdatedAt = 'updated_at',
  DisplayName = 'display_name',
}

export interface SortValue<
  T extends MetaobjectSortKey | ProductSortKeys | ProductWebSortKeys,
> {
  key: T;
  direction: 'asc' | 'desc';
}

export enum FilterType {
  Select,
  MultiSelect,
  ChoiceListWithSearch,
  AutocompleteSearch,
}

/** Replaces every key in a type TOriginal with a specific value TReplacement, like null or undefined */
export type ReplaceTypes<TOriginal, TReplacement> = {
  [K in keyof TOriginal]: TReplacement;
};

// Similar to what web does here: https://github.com/Shopify/web/blob/main/app/utilities/channel-picker/types.ts#L7
export enum AppId {
  OnlineStore = '580111',
}

export enum AppHandle {
  TranslateAndAdapt = 'translate-and-adapt',
}

export interface TransitionState {
  location: Location;
  action: NavigationType;
}

export type ReplaceType<TType, OldType, NewType> = OldType extends TType
  ? TType extends OldType
    ? NewType
    : TType
  : never;

/* Makes certain keys in the object optional, defaulting to everything being optional */
export type Optional<TType, TKeys extends keyof TType = keyof TType> = Omit<
  TType,
  TKeys
> &
  Partial<Pick<TType, TKeys>>;

/* Used for modals - you are either open with all of the types, or closed (open:false) with none of the types */
export type ModalInfo<TType> = ({open: true} & TType) | {open: false};

/**
 * Turns optional properties (TKeys) into required ones
 */
export type MarkRequired<TObj, TKeys extends keyof TObj> = Omit<TObj, TKeys> &
  Pick<Required<TObj>, TKeys>;

/**
 * Turns nullable properties K into non-nullable ones
 */
export type NonNullableProperty<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

export type Image = Pick<
  CoreTypesImage,
  'altText' | 'url' | 'id' | '__typename'
>;

export type FeaturedMedia =
  | {__typename: 'ExternalVideo'}
  | {
      __typename: 'MediaImage';
      image: Image | null;
    }
  | {__typename: 'Model3d'}
  | {__typename: 'Video'};
